import React from "react";

import cn from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-scroll";

import Icon from "@ui/icon";
import { MenuType } from "@utils/types";

import resumePDF from "../../data/images/resume.pdf";

const MainMenu = ({ menus, className, navId }) => {
    return (
        <nav
            id={navId}
            className={cn("mainmenu-nav navbar-example2", className)}
        >
            <ul className="primary-menu nav nav-pills">
                {menus.map(({ id, text, path, icon, download }) => (
                    <li key={id} className="nav-item">
                        {!download ? (
                            <Link
                                activeClass="active"
                                className="nav-link smoth-animation"
                                href={`#${path}`}
                                to={path}
                                spy={true}
                                smooth={true}
                                offset={-50}
                                duration={500}
                            >
                                {icon && <Icon name={icon} />}
                                {text}
                            </Link>
                        ) : (
                            <a
                                className="nav-link"
                                href={`${resumePDF}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {text} {icon && <Icon name={icon} />}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

MainMenu.propTypes = {
    menus: PropTypes.arrayOf(PropTypes.shape(MenuType)).isRequired,
    navId: PropTypes.string,
    className: PropTypes.string,
};

MainMenu.defaultProps = {
    navId: "sideNav",
};

export default MainMenu;
